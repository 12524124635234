import { Capacitor } from "@capacitor/core";
import { IonButton, IonInput, isPlatform } from "@ionic/react";
import clsx from "clsx";
import { useContext, useEffect, useRef, useState } from "react";
import { useFormControlContext } from "@/components/FormControlContext/useFormControlContext";
import { ToastContext } from "@/components/ToastContext";
import { SingleValidationError } from "@/util/customHooks/validation/useValidation";
import { trimZeros } from "./utils";
import "./numberInput.scss";

type NumberInputProps = {
    placeholder?: string;
    currentValue: number | null;
    step: number;
    onChange: (newValue: number | null) => void;
    disabled?: boolean;
    onlyPositive?: boolean;
    className?: string;
    onFocus?: () => void;
    onBlur?: () => void;
    error?: SingleValidationError;
    unit?: string;
    id?: string;
};

export const NumberInput = (props: NumberInputProps) => {
    const isAndroidDevice = isPlatform("android");
    const isIPhone = isPlatform("ios") && !isPlatform("tablet");
    const isAndroidApp = isPlatform("android") && isPlatform("mobile") && !isPlatform("mobileweb");
    const [stringValue, setStringValue] = useState<string | null | undefined>(
        constructStartingNumberStringValue(props.currentValue)
    );
    const inputRef = useRef<HTMLIonInputElement>(null);
    const lastPressedKey = useRef<string>();

    const { onMessage } = useContext(ToastContext);

    const { id: uncontrolledId, disabled: uncontrolledDisabled, state } = useFormControlContext();

    useEffect(() => {
        const currentInputRef = inputRef.current;

        function recordLastKey(event: KeyboardEvent) {
            lastPressedKey.current = event.key;
        }

        currentInputRef?.addEventListener("keyup", recordLastKey);
        return () => currentInputRef?.removeEventListener("keyup", recordLastKey);
    }, []);

    // Preserve fraction zeros
    // Without keeping it as a string 0.0 would convert to 0, so numbers < 0.1 would not be possible
    const updateValue = (value?: string | null) => {
        if (props.onlyPositive && value && value.indexOf("-") > -1) {
            onMessage("Hier kann keine negative Zahl eingegeben werden.", "warning");
            return;
        }

        setStringValue(trimZeros(value));

        /**
         * 14.01.2022
         * In Android apps, entering a dot (.) after a number causes the `onIonChange` (and the `onChange` of a native
         * input as well) method to be fired with an empty string. This by itself cannot be differentiated from simply
         * deleting all input (firing with empty string, too). It also sets the value of the `IonInput` as well as the
         * native input to an empty string. Hence, we check the last pressed key to identify which
         * of the two cases (dot or deletion) has taken place. Note that older devices / browsers might not report
         * "Backspace" as the event key (it is still better support than dot or comma, which - even on modern devices -
         * are mostly reported as "Unidentified"). Hence, it may occur that a user deletes the complete input but the
         * input does not report the last deletion, leaving the parent function thinking that one digit is still in
         * there. However, we found no better solution.
         */
        const valueIsEmpty = value === undefined || value === null || value === "";
        if (valueIsEmpty && isAndroidApp && lastPressedKey.current !== "Backspace") {
            return;
        }
        props.onChange(value ? Number(value) : null);
    };

    // It could take some time before props.currentValue is first set (example: load data from API)
    useEffect(() => {
        if (typeof props.currentValue === "number" && props.currentValue !== Number(stringValue)) {
            setStringValue(String(props.currentValue));
        } else if (props.currentValue === null || props.currentValue === undefined) {
            setStringValue("");
        }
        // do not include `stringValue`, this breaks the logic of keeping fractions etc internally
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentValue]);

    const handleSignChange = () => {
        let newStringValue;
        if (stringValue?.startsWith("-")) {
            newStringValue = stringValue?.substring(1);
        } else {
            newStringValue = `-${stringValue}`;
        }
        props.onChange(Number(newStringValue));
        setStringValue(newStringValue);
    };

    const isMobile = isAndroidDevice || isIPhone;
    const showSignButton = isMobile && !props.onlyPositive;

    const isDisabled = props.disabled || uncontrolledDisabled;

    return (
        <div
            className={clsx(
                "number-input",
                {
                    "number-input--state-normal": state === "normal",
                    "number-input--state-warning": state === "warning",
                    "number-input--state-error": state === "error" || props.error,
                },
                {
                    "number-input--disabled": isDisabled,
                },
                props.className
            )}>
            {/* native android apps cannot accept decimal input when using a native html input*/}
            {isPlatform("android") && Capacitor.isNativePlatform() ? (
                <IonInput
                    id={props.id || uncontrolledId}
                    className="number-input__input"
                    type="number"
                    inputMode={props.step < 1 ? "decimal" : "numeric"}
                    step={props.step.toString()}
                    placeholder={props.placeholder}
                    min={props.onlyPositive ? "0" : undefined}
                    value={stringValue}
                    onIonInput={e => updateValue(e.detail.value)}
                    disabled={isDisabled}
                    ref={inputRef}
                    onIonFocus={props.onFocus}
                    onIonBlur={props.onBlur}
                />
            ) : (
                <input
                    id={props.id || uncontrolledId}
                    className="number-input__input"
                    type="number"
                    inputMode={props.step < 1 ? "decimal" : "numeric"}
                    step={props.step}
                    placeholder={props.placeholder}
                    min={props.onlyPositive ? 0 : undefined}
                    value={(Number(stringValue) !== props.currentValue ? props.currentValue ?? "" : stringValue) ?? ""}
                    onChange={e => updateValue(e.target.value)}
                    disabled={isDisabled}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                />
            )}
            {props.unit && <span>{props.unit}</span>}
            {/* Android keyboards are vendor specific and some don't offer a minus key */}
            {/* iPhones (as opposed to iPads) don't offer a minus key, either */}
            {showSignButton && (
                <IonButton className="number-input__sign" size="small" fill="solid" onClick={handleSignChange}>
                    +/-
                </IonButton>
            )}
        </div>
    );
};

// Makes sure -0 appears as -0 for starting value
function constructStartingNumberStringValue(value: number | null | undefined) {
    if (value === 0) {
        // Because Math.sign("-INFINITY") === -1
        const sign = Math.sign(1 / Math.sign(value));
        if (sign === -1) {
            return "-0";
        }
    }
    return String(value);
}
