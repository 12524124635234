import { Describe, array, boolean, literal, number, object, string, union } from "superstruct";
import { Customer } from "./Customer";
import { CustomerTag } from "./CustomerTag";
import { Service } from "./services/Service";
import { AnyPriceStructure, ServicePriceBlockBase, anyPriceStructureStruct } from "./services/ServicePriceBlocks";

export class PriceAgreement {
    archived: boolean;
    id: string;
    customerTarget: AnyCustomerTarget;
    priceTarget: AnyPriceAgreementPrice;
    /**
     * Date in YYYY-MM-DD format.
     */
    start: string;
    /**
     * Date in YYYY-MM-DD format.
     */
    end: string;
    note: string;

    constructor(initialValues?: Partial<PriceAgreement>) {
        this.archived = initialValues?.archived ?? false;
        this.id = initialValues?.id ?? "";
        this.customerTarget = initialValues?.customerTarget ?? {
            type: CustomerTargetType.SPECIFIC_CUSTOMERS,
            customerIds: [],
        };
        this.priceTarget = initialValues?.priceTarget ?? {
            type: PriceAgreementPriceType.SERVICE,
            serviceId: "",
            overrides: [],
        };
        this.start = initialValues?.start ?? "";
        this.end = initialValues?.end ?? "";
        this.note = initialValues?.note ?? "";
    }
}

export enum CustomerTargetType {
    SPECIFIC_CUSTOMERS = "CustomerTargetType_SPECIFIC_CUSTOMERS",
    CUSTOMER_TAGS = "CustomerTargetType_CUSTOMER_TAGS",
}

type SpecificCustomerTarget = {
    type: CustomerTargetType.SPECIFIC_CUSTOMERS;
    customerIds: Customer["id"][];
};

type CustomerTagTarget = {
    type: CustomerTargetType.CUSTOMER_TAGS;
    tagIds: CustomerTag["id"][];
};

type AnyCustomerTarget = SpecificCustomerTarget | CustomerTagTarget;

const customerTagTargetStruct: Describe<CustomerTagTarget> = object({
    type: literal(CustomerTargetType.CUSTOMER_TAGS),
    tagIds: array(string()),
});

const specificCustomerTargetStruct: Describe<SpecificCustomerTarget> = object({
    type: literal(CustomerTargetType.SPECIFIC_CUSTOMERS),
    customerIds: array(string()),
});

const anyCustomerTargetStruct: Describe<PriceAgreementForValidation["customerTarget"]> = union([
    number(),
    specificCustomerTargetStruct,
    customerTagTargetStruct,
]);

export enum PriceAgreementPriceType {
    SERVICE = "PriceAgreementPriceType_SERVICE",
}

export type ServicePrice = {
    type: PriceAgreementPriceType.SERVICE;
    serviceId: Service["id"];
    overrides: ServicePriceBlockOverride[];
};

type AnyPriceAgreementPrice = ServicePrice;

export type ServicePriceBlockOverride = {
    priceBlockId: ServicePriceBlockBase["id"];
    priceOverride: AnyPriceStructure;
};

// This is a struct for validation purposes, because of an issue with superstruct and union typed with objects https://github.com/ianstormtaylor/superstruct/issues/1229
type PriceAgreementForValidation = Omit<PriceAgreement, "customerTarget"> & {
    customerTarget: AnyCustomerTarget | number;
};

export const priceAgreementStruct: Describe<PriceAgreementForValidation> = object({
    archived: boolean(),
    id: string(),
    customerTarget: anyCustomerTargetStruct,
    priceTarget: object({
        type: literal(PriceAgreementPriceType.SERVICE),
        serviceId: string(),
        overrides: array(
            object({
                priceBlockId: string(),
                priceOverride: anyPriceStructureStruct,
            })
        ),
    }),
    start: string(),
    end: string(),
    note: string(),
});
