import { AppCompany } from "@farmact/model/src/model/AppCompany";
import { Customer } from "@farmact/model/src/model/Customer";
import { Track } from "@farmact/model/src/model/Track";
import { query, where } from "firebase/firestore";
import { useCallback, useMemo } from "react";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";
import { useMergedQueryData } from "@/firebase/utils/useMergedQueryData/useMergedQueryData";
import { useStableIdArray } from "@/util/customHooks/useStableArray";

type UseTrackOptions = {
    companyId?: string;
    includeArchived?: boolean;
};
interface UseTracksReturn {
    tracks: Track[];
    loading: boolean;
}

export function useTracks(trackIds: string[], options: UseTrackOptions = {}): UseTracksReturn {
    const { companyId } = options;

    const stableTrackIds = useStableIdArray(trackIds);

    const queryGenerator = useCallback(
        (trackIds: string[]) => {
            return query(
                Firebase.instance().getAllTracks({
                    companyId,
                    include: options.includeArchived ? "all" : "non-archived",
                }),
                where("id", "in", trackIds)
            );
        },
        [companyId, options.includeArchived]
    );

    const [tracks, loading] = useMergedQueryData(stableTrackIds, queryGenerator);

    return {
        tracks,
        loading,
    };
}

type UseCustomerTracksParams = {
    companyId: AppCompany["id"] | undefined;
    customerId: Customer["id"] | undefined;
    archived: boolean;
};

export function useCustomerTracks(params: UseCustomerTracksParams) {
    const { customerId, companyId, archived } = params;

    const tracksQuery = useMemo(() => {
        if (!customerId) {
            return;
        }

        return Firebase.instance().getAllTracksForCustomer({
            customerId,
            companyId: companyId,
            include: archived ? "archived" : "non-archived",
        });
    }, [customerId, companyId, archived]);

    return useCollectionData(tracksQuery, [tracksQuery]);
}
