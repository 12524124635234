import clsx from "clsx";
import dayjs from "dayjs";
import { useFormControlContext } from "@/components/FormControlContext/useFormControlContext";
import { DateInput } from "@/components/inputs/DateInput/DateInput";
import { TimeInput, TimeInputValue } from "@/components/inputs/TimeInput/TimeInput";
import { SingleValidationError } from "@/util/customHooks/validation/useValidation";
import { computed } from "@/util/functions";
import { DateLike } from "@/util/timeUtils";
import "./dateTimeInput.scss";

export type DateTimeInputProps = {
    disabled?: boolean;
    value?: DateLike | null;
    onValueChange?: (value: Date) => void;
    min?: DateLike | null;
    max?: DateLike | null;

    error?: SingleValidationError;

    className?: string;
    classNames?: {
        root?: string;
        date?: string;
        time?: string;
    };
};

export const DateTimeInput = (props: DateTimeInputProps) => {
    const { id } = useFormControlContext();

    const getMinRefDate = (): Date | null => {
        if (props.value) {
            return dayjs(props.value).toDate();
        } else if (props.min) {
            return dayjs(props.min).toDate();
        }

        return null;
    };

    const handleDateChange = (date: Date | null) => {
        if (date === null) {
            return;
        }

        const newDate = dayjs(date);
        const refDate = getMinRefDate();

        const updatedDateTime = refDate
            ? dayjs(refDate).year(newDate.year()).month(newDate.month()).date(newDate.date())
            : newDate;

        if (!updatedDateTime.isValid()) {
            return;
        }
        props.onValueChange?.(updatedDateTime.toDate());
    };

    const handleTimeChange = (value: TimeInputValue | undefined) => {
        if (!value) {
            return;
        }

        const nextDateTime = computed((): dayjs.Dayjs => {
            const refDate = getMinRefDate();
            if (!refDate) {
                return dayjs().hour(value.hours).minute(value.minutes).startOf("second");
            }

            return dayjs(refDate).hour(value.hours).minute(value.minutes).startOf("second");
        });

        props.onValueChange?.(nextDateTime.toDate());
    };

    return (
        <div className={clsx("date-time-input", props.className, props.classNames?.root)}>
            <DateInput
                id={id}
                disabled={props.disabled}
                className={clsx("date-time-input__date", props.classNames?.date)}
                error={props.error}
                placeholder="Datum"
                value={props.value}
                onChange={handleDateChange}
                min={props.min ? dayjs(props.min).toDate() : undefined}
                max={props.max ? dayjs(props.max).toDate() : undefined}
            />

            <TimeInput
                disabled={props.disabled}
                error={props.error}
                value={toTimeInputValue(props.value || undefined)}
                onValueChange={handleTimeChange}
                className="date-time-input__time"
            />
        </div>
    );
};

function toTimeInputValue(date: DateLike | undefined): TimeInputValue | undefined {
    if (!date) {
        return;
    }

    const dateValue = dayjs(date);
    return {
        hours: dateValue.hour(),
        minutes: dateValue.minute(),
    };
}
