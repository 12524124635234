import { Customer } from "@farmact/model/src/model/Customer";
import Field from "@farmact/model/src/model/Field";
import { LiquidMixture } from "@farmact/model/src/model/LiquidMixture";
import { Marker } from "@farmact/model/src/model/Marker";
import { Order } from "@farmact/model/src/model/Order";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { Track } from "@farmact/model/src/model/Track";
import { createContext, useContext } from "react";

export function useOrderContext() {
    return useContext(OrderContext);
}
export const defaultOrderContext: OrderContextType = {
    order: new Order(),
    orderFields: [],
    orderTracks: [],
    orderAndCustomerMarkers: [],
    customerFields: [],
    customerTracks: [],
    timeTrackings: [],
    currentlyActiveCustomer: null,
    liquidMixtures: [],
    onAddLiquidMixture: () => {},
    onRemoveLiquidMixture: () => {},
};

export const OrderContext = createContext<OrderContextType>(defaultOrderContext);
export type OrderContextType = {
    order: Order;
    orderFields: Field[];
    orderTracks: Track[];
    /**
     * Order markers and customer markers (not including field markers!)
     */
    orderAndCustomerMarkers: Marker[];
    /**
     * All available fields (including non-selected)
     */
    customerFields: Field[];
    /**
     * All available tracks (including non-selected)
     */
    customerTracks: Track[];
    /**
     * All time trackings of this order
     */
    timeTrackings: TimeTracking[];
    /**
     * Customer that is currently being worked for. null if order is not currently active or no customer is selected.
     */
    currentlyActiveCustomer: Customer | null;
    /**
     * Available liquid mixtures. Where a new order is being created, these are not yet persisted.
     */
    liquidMixtures: LiquidMixture[];
    /**
     * Call this function when a new valid liquid mixture should be added.
     * @param newLiquidMixture
     */
    onAddLiquidMixture: (newLiquidMixture: LiquidMixture) => void;
    /**
     * Call this function when n existing liquid mixture should be removed.
     * @param newLiquidMixtureId
     */
    onRemoveLiquidMixture: (liquidMixtureId: LiquidMixture["id"]) => void;
};
