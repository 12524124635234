import { LoadingOrUnloadingPoint } from "@farmact/model/src/model/LoadingOrUnloadingPoint";
import { query, where } from "firebase/firestore";
import { useCallback, useMemo } from "react";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";
import { useMergedQueryData } from "@/firebase/utils/useMergedQueryData/useMergedQueryData";
import { useStableIdArray } from "@/util/customHooks/useStableArray";

type UseLoadingOrUnloadingPointsReturn = {
    loadingOrUnloadingPoints: LoadingOrUnloadingPoint[];
    loading: boolean;
};

export function useLoadingOrUnloadingPoints(loadingOrUnloadingPointIds: string[]): UseLoadingOrUnloadingPointsReturn {
    const stableLoadingOrUnloadingPointIds = useStableIdArray(loadingOrUnloadingPointIds);

    const queryGenerator = useCallback((loadingOrUnloadingPointIds: string[]) => {
        return query(
            Firebase.instance().getAllLoadingOrUnloadingPoints({ companyId: undefined }),
            where("id", "in", loadingOrUnloadingPointIds)
        );
    }, []);

    const [loadingOrUnloadingPoints, loading] = useMergedQueryData(stableLoadingOrUnloadingPointIds, queryGenerator);

    return {
        loadingOrUnloadingPoints,
        loading,
    };
}

type UseLoadingOrUnloadingPointsForCustomerOptions = {
    companyId: string | undefined;
    archived: boolean;
};

export function useLoadingOrUnloadingPointsForCustomer(
    customerId: string | null | undefined,
    options: UseLoadingOrUnloadingPointsForCustomerOptions
): UseLoadingOrUnloadingPointsReturn {
    const { companyId, archived } = options;

    const loadingOrUnloadingPointsQuery = useMemo(() => {
        if (customerId === undefined) {
            return;
        }

        if (customerId) {
            return Firebase.instance().getAllLoadingOrUnloadingPointsForCustomers({
                customerIds: [customerId],
                companyId,
                include: archived ? "archived" : "non-archived",
            });
        }

        return Firebase.instance().getAllCommonLoadingOrUnloadingPoints(archived ? "archived" : "non-archived");
    }, [customerId, companyId, archived]);

    const [loadingOrUnloadingPoints, loading] = useCollectionData(loadingOrUnloadingPointsQuery, [
        loadingOrUnloadingPointsQuery,
    ]);

    return {
        loadingOrUnloadingPoints,
        loading,
    };
}
