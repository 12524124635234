import { Customer } from "@farmact/model/src/model/Customer";
import { LoadingOrUnloadingPoint } from "@farmact/model/src/model/LoadingOrUnloadingPoint";
import { Query, query, where } from "firebase/firestore";
import { Firebase } from "@/firebase";
import { useMergedQueryData } from "@/firebase/utils/useMergedQueryData/useMergedQueryData";
import { useStableIdArray } from "@/util/customHooks/useStableArray";
import { ModelOrId, getModelId } from "@/util/select";

type UseCustomersLoadingOrUnloadingPointsReturn = {
    loadingOrUnloadingPoints: LoadingOrUnloadingPoint[];
    loading: boolean;
};

export function useCustomersLoadingOrUnloadingPoints(
    customers: ModelOrId<Customer>[]
): UseCustomersLoadingOrUnloadingPointsReturn {
    const customerIds = useStableIdArray(customers.map(getModelId));

    const [loadingOrUnloadingPoints, loading] = useMergedQueryData(customerIds, getCustomersLoadingOrUnloadingPoints);

    return {
        loadingOrUnloadingPoints,
        loading,
    };
}

function getCustomersLoadingOrUnloadingPoints(customerIds: Array<Customer["id"]>): Query<LoadingOrUnloadingPoint> {
    return query(
        Firebase.instance().getAllLoadingOrUnloadingPoints({ companyId: undefined }),
        where("customerId", "in", customerIds)
    );
}
