import { faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faAnglesUpDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonSpinner, isPlatform } from "@ionic/react";
import { Combobox, FloatingPosition, useCombobox } from "@mantine/core";
import clsx from "clsx";
import { CSSProperties, ChangeEvent, Fragment, MouseEvent, ReactNode, useState } from "react";
import { FormControlState } from "@/components/FormControlContext/FormControlContext";
import { useFormControlContext } from "@/components/FormControlContext/useFormControlContext";
import { useLoading } from "@/util/customHooks/loadingHook/useLoading";
import { SingleValidationError } from "@/util/customHooks/validation/useValidation";
import { computed } from "@/util/functions";
import { getPortalTarget } from "@/util/overlayUtils";
import { SelectOption } from "@/util/select";
import { ComboboxOption } from "./ComboboxOption/ComboboxOption";
import { defaultSelectSearchFilter } from "./singleSelectUtils";
import "./singleSelect.scss";

export type SelectProps = {
    data: SelectOption[];
    /**
     * @default "Auswählen"
     */
    placeholder?: string;
    state?: FormControlState;
    error?: SingleValidationError;
    warning?: SingleValidationError;
    disabled?: boolean;
    /**
     * @default true
     */
    clearable?: boolean;
    /**
     * @default true
     */
    searchable?: boolean;
    /**
     * @default "Durchsuchen"
     */
    searchPlaceholder?: string;
    search?: string;
    /**
     * Controlled search.
     * This means that you have to filter `data` on your own
     */
    onSearchChange?: (search: string) => void;
    /**
     * @default "Nichts gefunden"
     */
    emptyMessage?: string | EmptyMessageAccessor;
    loading?: boolean;
    labelSort?: boolean;

    groupSort?: (group1: string, group2: string) => number;

    dropdownPosition?: FloatingPosition;
    dropdownOpen?: boolean;
    onDropdownOpenChange?: (open: boolean) => void;

    className?: string;
    style?: CSSProperties;
    "data-testid"?: string;
};

export type SingleSelectProps = SelectProps & {
    value: string | null | undefined;
    onChange: (value: string | null) => void;
};

export const SingleSelect = (props: SingleSelectProps) => {
    const {
        placeholder = "Auswählen",
        clearable = true,
        searchable = true,
        searchPlaceholder = "Durchsuchen",
        emptyMessage = "Nichts gefunden",
        loading = false,
        labelSort = true,
    } = props;

    const { id, disabled: uncontrolledDisabled, state: uncontrolledState } = useFormControlContext();

    const [triggerRef, setTriggerRef] = useState<HTMLButtonElement | null>(null);
    const [uncontrolledSearch, setUncontrolledSearch] = useState("");
    const isControlledSearch = !!props.onSearchChange;

    const showSpinner = useLoading(loading);

    const combobox = useCombobox({
        opened: props.dropdownOpen,
        onOpenedChange: props.onDropdownOpenChange,
        onDropdownOpen: () => {
            if (!isPlatform("mobile") && combobox.searchRef.current) {
                combobox.focusSearchInput();
            }
        },
        onDropdownClose: () => {
            setUncontrolledSearch("");
            props.onSearchChange?.("");
            combobox.resetSelectedOption();
            combobox.focusTarget();
        },
    });

    const handleTargetClick = () => {
        if (combobox.dropdownOpened) {
            combobox.closeDropdown();
        } else {
            combobox.openDropdown();
        }
    };

    const handleOptionSubmit = (value: string) => {
        props.onChange(value);
        combobox.closeDropdown();
    };

    const handleClear = (event: MouseEvent) => {
        event.stopPropagation();

        props.onChange(null);
        combobox.closeDropdown();
    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        setUncontrolledSearch(value);
        props.onSearchChange?.(value);

        combobox.updateSelectedOptionIndex("selected");
    };

    const rightSection = computed((): ReactNode => {
        if (props.value && clearable) {
            return (
                <FontAwesomeIcon icon={faXmark} onClick={handleClear} className="single-select-right-section__clear" />
            );
        }

        return <FontAwesomeIcon icon={faAnglesUpDown} className="single-select-right-section__chevron" />;
    });

    const validOptions = props.data.filter(option => {
        return !option.incompatible;
    });
    const selectableData = computed(() => {
        if (isControlledSearch) {
            // filtering is in controlled mode.
            // options should be passed pre-filtered as prop (`data`)
            return validOptions;
        }

        return defaultSelectSearchFilter(validOptions, uncontrolledSearch);
    });

    const renderedEmptyMessage = computed((): string | undefined => {
        if (selectableData.length > 0) {
            return;
        }
        if (loading) {
            return;
        }

        return typeof emptyMessage === "string" ? emptyMessage : emptyMessage(uncontrolledSearch);
    });

    const groupedOptions = computed(() => {
        const groupedOptions: Record<string, SelectOption[]> = {};
        selectableData.forEach(option => {
            const optionGroup = option.group ?? "noGroup";
            if (!groupedOptions[optionGroup]) {
                groupedOptions[optionGroup] = [];
            }
            groupedOptions[optionGroup].push(option);
        });

        const options = Object.entries(groupedOptions)
            .sort(([groupAKey], [groupBKey]) => (props.groupSort ? props.groupSort(groupAKey, groupBKey) : 0))
            .map(([group, options]) => {
                const sortFunction = (a: SelectOption, b: SelectOption) => a.label.localeCompare(b.label);
                const sortedOptions = labelSort ? [...options].sort(sortFunction) : options;
                const groupOptions = sortedOptions.map(option => {
                    const selected = props.value === option.value;
                    return <ComboboxOption key={option.value} option={option} selected={selected} />;
                });

                return (
                    <Fragment key={group}>
                        {group === "noGroup" ? (
                            groupOptions
                        ) : (
                            <Combobox.Group label={<div className="multi-select__group-label">{group}</div>}>
                                {groupOptions}
                            </Combobox.Group>
                        )}
                    </Fragment>
                );
            });
        return options;
    });

    const state = computed((): FormControlState => {
        if (props.error) {
            return "error";
        } else if (props.warning) {
            return "warning";
        }
        return props.state ?? uncontrolledState;
    });

    const selectedOption = props.data.find(option => {
        return option.value === props.value;
    });

    const showPlaceholder = !selectedOption;
    const showSearch = searchable && (isControlledSearch || props.data.length > 0);

    const search = props.search ?? uncontrolledSearch;

    return (
        <Combobox
            store={combobox}
            onOptionSubmit={handleOptionSubmit}
            position={props.dropdownPosition}
            portalProps={{
                target: getPortalTarget(triggerRef),
            }}
            // @ts-ignore TODO: is this possible in another way?
            clickOutsideEvents={["mousedown"]}>
            <Combobox.Target targetType="button">
                <button
                    ref={setTriggerRef}
                    data-testid={props["data-testid"]}
                    id={id}
                    type="button"
                    disabled={props.disabled || uncontrolledDisabled}
                    onClick={handleTargetClick}
                    className={clsx("single-select", props.className, {
                        "single-select--state-normal": state === "normal",
                        "single-select--state-warning": state === "warning",
                        "single-select--state-error": state === "error",
                    })}
                    style={props.style}>
                    {showPlaceholder && <span className="single-select__placeholder">{placeholder}</span>}
                    {selectedOption && <span className="single-select__value">{selectedOption.label}</span>}

                    <span className="single-select__right-section">{rightSection}</span>
                </button>
            </Combobox.Target>

            <Combobox.Dropdown className="single-select__dropdown">
                {showSearch && (
                    <Combobox.Search
                        value={search}
                        onChange={handleSearchChange}
                        placeholder={searchPlaceholder}
                        className="single-select__search"
                        leftSection={<FontAwesomeIcon icon={faSearch} />}
                        rightSection={showSpinner ? <IonSpinner /> : null}
                    />
                )}
                {(groupedOptions.length > 0 || renderedEmptyMessage) && (
                    <Combobox.Options className="single-select__options">
                        {renderedEmptyMessage && (
                            <Combobox.Empty className="single-select__empty">{renderedEmptyMessage}</Combobox.Empty>
                        )}

                        {groupedOptions}
                    </Combobox.Options>
                )}
            </Combobox.Dropdown>
        </Combobox>
    );
};

export type EmptyMessageAccessor = (search: string) => string;
