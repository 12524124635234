export const firebaseConfig = {
    apiKey: "AIzaSyAdbKh8l9VK5S7ShrK9GGw-I_Dd-IsYSlo",
    authDomain: "beta-farmact.firebaseapp.com",
    databaseURL: "https://beta-farmact.firebaseio.com",
    projectId: "beta-farmact",
    storageBucket: "beta-farmact.appspot.com",
    messagingSenderId: "153380373219",
    appId: "1:153380373219:web:6f3242b3251e8fc647fbd6",
    measurementId: "G-M34023FZ76",
};

export const environment: any = "beta";

export const bugSnagApiKey = "93a99a8bfa4ebe65ea274863cd6c87cc";

export const appVersion = {
    major: 3,
    minor: 31,
    patch: 10,
};

export const stripePublicKey =
    "pk_test_51HEUp0DMi53eMe7D9g5p2VpyUiQrXUqXXVyaCYIFsdUfyIgQOqFvxuk3XCt0v0je0GiNu3yiuz5hGaO6MXYju7qK00iGo8lPIX";

export const hotJarId = 2971493;

export const localAddress = "";

export const googleMapId = "af5c2dc1f7ef012";
