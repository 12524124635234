import * as Routes from "@farmact/model/src/constants/farmActAppRoutes";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { IonReactRouter } from "@ionic/react-router";
import { Font } from "@react-pdf/renderer";
import dayjs from "dayjs";
import "dayjs/locale/de";
import customParseFormatPlugin from "dayjs/plugin/customParseFormat";
import relativeTimePlugin from "dayjs/plugin/relativeTime";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router";
import { Route } from "react-router-dom";
import { CampaignsPage } from "@/components/campaigns/CampaignsPage/CampaignsPage";
import { PriceAgreementsPage } from "@/components/organization/priceAgreements/PriceAgreementsPage/PriceAgreementsPage";
import { Firebase } from "@/firebase";
import { useDocumentData } from "@/firebase/dataHooks";
import { useBaseData } from "@/util/customHooks/useBaseData";
import { useNoSleep } from "@/util/customHooks/useNoSleep";
import { useReportDiagnostics } from "@/util/customHooks/useReportDiagnostics";
import { AccountPage } from "./Account/AccountPage/AccountPage";
import { StripeErrorPage } from "./Account/StripeErrorPage/StripeErrorPage";
import { RevenueDevelopmentPage } from "./Analyse/RevenueDevelopment/RevenueDevelopmentPage";
import { SignInPage } from "./authentication/SignIn/SignInPage";
import { SignUpPage } from "./authentication/SignUp/SignUpPage";
import { useConfigureApiClients } from "./authentication/useConfigureApiClients";
import { useWatchEmployeeDeactivation } from "./authentication/useWatchEmployeeDeactivation";
import { BankConnectionRedirectPage } from "./BankConnectionRedirectPage/BankConnectionRedirectPage";
import { CampaignDetailsPage } from "./campaigns/CampaignDetailsPage/CampaignDetailsPage";
import { ContextWrapper } from "./ContextWrapper";
import { CollaborationPage } from "./Contractors/CollaborationPage/CollaborationPage";
import { ContractorsOrderRequestCreatePage } from "./Contractors/ContractorsOrderRequestCreatePage/ContractorsOrderRequestCreatePage";
import { ContractorsOrderRequestDetailsPage } from "./Contractors/ContractorsOrderRequestDetailsPage/ContractorsOrderRequestDetailsPage";
import { ContractorsOrderRequestsArchivePage } from "./Contractors/ContractorsOrderRequestsArchivePage/ContractorsOrderRequestsArchivePage";
import { ContractorsOrderRequestPage } from "./Contractors/ContractorsOrderRequestsPage/ContractorsOrderRequestsPage";
import { ContractorsPage } from "./Contractors/ContractorsPage/ContractorsPage";
import { CostAccountsPage } from "./costAccounts/CostAccountsPage";
import { CustomMailActionPage } from "./CustomMailActionPage/CustomMailActionPage";
import { GlobalSettingsPage } from "./GlobalSettingsPage/GlobalSettingsPage";
import { HomePage } from "./Home/HomePage";
import { IncomingBillDetailsPage } from "./incomingReceipts/incomingBills/IncomingBillDetailsPage/IncomingBillDetailsPage";
import { IncomingBillPage } from "./incomingReceipts/incomingBills/IncomingBillsPage/IncomingBillsPage";
import { InternalWorkPage } from "./internalWork/InternalWorkPage";
import { LandingPage } from "./Landing";
import { LocationsMapPage } from "./LocationsMapPage/LocationsMapPage";
import { MapStructuresSharingPage } from "./MapStructuresSharingPage/MapStructuresSharingPage";
import { NotFoundPage } from "./NotFound/NotFoundPage";
import { Notification } from "./Notification/Notification";
import { DispositionPage } from "./orders/disposition/DispositionPage";
import { CreateOrderPage } from "./orders/OrdersAdministration/CreateOrderPage/CreateOrderPage";
import { OrderDetailsPage } from "./orders/OrdersAdministration/EditOrderPage/OrderDetailsPage";
import { OrderRequestDetailsPage } from "./orders/OrdersAdministration/OrderRequestDetailsPage/OrderRequestDetailsPage";
import { OrderRequestsArchivePage } from "./orders/OrdersAdministration/OrderRequestsArchivePage/OrderRequestsArchivePage";
import { OrderRequestsPage } from "./orders/OrdersAdministration/OrderRequestsPage/OrderRequestsPage";
import { OrdersAdministrationPage } from "./orders/OrdersAdministration/OrdersAdministrationPage/OrdersAdministrationPage";
import { OrdersArchivePage } from "./orders/OrdersAdministration/OrdersArchivePage/OrdersArchivePage";
import { OrderSettingsPage } from "./orders/OrdersAdministration/OrderSettingsPage/OrderSettingsPage";
import { TaskDetailsPage } from "./orders/Tasks/TaskDetailsPage/TaskDetailsPage";
import { TasksPage } from "./orders/Tasks/TasksPage/TasksPage";
import { OrdersProgressSharingPage } from "./OrdersProgressSharingPage/OrdersProgressSharingPage";
import { CompanyPage } from "./organization/CompanyPage/CompanyPage";
import { SupplierDetailsPage } from "./organization/CompanyPage/suppliers/SupplierDetailsPage/SupplierDetailsPage";
import { SuppliersPage } from "./organization/CompanyPage/suppliers/SuppliersPage";
import { SuppliersSettingsPage } from "./organization/CompanyPage/suppliers/SuppliersSettingsPage/SuppliersSettingsPage";
import { CustomerDetailsPage } from "./organization/customers/CustomerDetailsPage/CustomerDetailsPage";
import { CustomersMapPage } from "./organization/customers/CustomersPage/CustomersMapPage/CustomersMapPage";
import { CustomersPage } from "./organization/customers/CustomersPage/CustomersPage";
import { CustomersSettingsPage } from "./organization/customers/CustomersSettingsPage/CustomerSettingsPage";
import { CustomerTagsPage } from "./organization/customers/CustomerTagsPage/CustomerTagsPage";
import { EmployeeDetailsPage } from "./organization/employees/EmployeeDetailsPage/EmployeeDetailsPage";
import { EmployeesPage } from "./organization/employees/EmployeesPage";
import { EmployeesTimeTrackingsPage } from "./organization/employees/EmployeesTimeTrackingsPage/EmployeesTimeTrackingsPage";
import { GasStationsPage } from "./organization/gasStations/GasStationsPage";
import { LoadingOrUnloadingPointsPage } from "./organization/loadingOrUnloadingPoints/LoadingOrUnloadingPointsPage";
import { MachineDetailsPage } from "./organization/machines/MachineDetailsPage/MachineDetailsPage";
import { MachineHistoryPage } from "./organization/machines/MachineDetailsPage/MachineHistoryPage/MachineHistoryPage";
import { MachinesPage } from "./organization/machines/MachinesPage/MachinesPage";
import { OrganizationPage } from "./organization/overview/OrganizationPage";
import { PriceAgreementDetailsPage } from "./organization/priceAgreements/PriceAgreementDetailsPage/PriceAgreementDetailsPage";
import { ResourceDetailsPage } from "./organization/resources/ResourceDetailsPage/ResourcesDetailsPage";
import { ResourceHistoryPage } from "./organization/resources/ResourceHistoryPage/ResourceHistoryPage";
import { ResourcesPage } from "./organization/resources/ResourcesPage";
import { ResourcesTagsPage } from "./organization/resources/resourceTags/ResourceTagsPage/ResourceTagsPage";
import { RentalsOverviewPage } from "./organization/services/rental/RentalsOverviewPage";
import { ServiceCatalogPage } from "./organization/services/ServiceCatalogPage/ServiceCatalogPage";
import { ServiceDetailsPage } from "./organization/services/ServiceDetailPage/ServiceDetailsPage";
import { ServicesPage } from "./organization/services/ServicesPage/ServicesPage";
import { ProjectDetailsPage } from "./projects/ProjectDetailsPage/ProjectDetailsPage";
import { ProjectsPage } from "./projects/ProjectsPage/ProjectsPage";
import { PushNotifications } from "./PushNotifications";
import { BillDetailsPage } from "./receipts/bills/BillDetail/BillDetailsPage/BillDetailsPage";
import { CancellationBillPage } from "./receipts/bills/BillDetail/CancellationBillPage/CancellationBillPage";
import { CreateBillPage } from "./receipts/bills/BillDetail/CreateBillPage/CreateBillPage";
import { BillReminderDetailsPage } from "./receipts/bills/billReminders/BillReminderDetailsPage/BillReminderDetailsPage";
import { BillRemindersOverviewPage } from "./receipts/bills/billReminders/BillRemindersOverviewPage/BillRemindersOverviewPage";
import { BillRemindersForBillPage } from "./receipts/bills/billReminders/BillRemindersPage/BillRemindersForBillPage";
import { BillsArchivePage } from "./receipts/bills/BillsArchive/BillsArchivePage";
import { BillSettingsPage } from "./receipts/bills/BillSettingsPage/BillSettingsPage";
import { BillsPage } from "./receipts/bills/BillsPage/BillsPage";
import { CreateCreditNotePage } from "./receipts/CreditNotes/CreateCreditNotePage/CreateCreditNotePage";
import { CreditNoteDetailsPage } from "./receipts/CreditNotes/CreditNoteDetailsPage/CreditNoteDetailsPage";
import { CreditNotesArchivePage } from "./receipts/CreditNotes/CreditNotesArchivePage/CreditNotesArchivePage";
import { CreditNotesSettingsPage } from "./receipts/CreditNotes/CreditNoteSettingsPage/CreditNotSettingsPage";
import { CreditNotesPage } from "./receipts/CreditNotes/CreditNotesPage/CreditNotesPage";
import { CreateDeliveryNotePage } from "./receipts/deliveryNotes/CreateDeliveryNotePage/CreateDeliveryNotePage";
import { DeliveryNoteDetailsPage } from "./receipts/deliveryNotes/DeliveryNoteDetailsPage/DeliveryNoteDetailsPage";
import { DeliveryNotesArchivePage } from "./receipts/deliveryNotes/DeliveryNotesArchivePage/DeliveryNotesArchivePage";
import { DeliveryNoteSettingsPage } from "./receipts/deliveryNotes/DeliveryNoteSetttingsPage/DeliveryNoteSettingsPage";
import { DeliveryNotesPage } from "./receipts/deliveryNotes/DeliveryNotesPage/DeliveryNotesPage";
import { CreateOfferPage } from "./receipts/offers/CreateOfferPage/CreateOfferPage";
import { OfferArchivePage } from "./receipts/offers/OfferArchivePage/OfferArchivePage";
import { OfferDetailsPage } from "./receipts/offers/OfferDetailsPage/OfferDetailsPage";
import { OfferSettingsPage } from "./receipts/offers/OfferSettingsPage/OfferSettingsPage";
import { OffersPage } from "./receipts/offers/OffersPage/OffersPage";
import { ReceiptMenuPage } from "./receipts/ReceiptDetailsPage/ReceiptMenuPage";
import { AddRentalOrderPage } from "./rentalOrders/AddRentalOrderPage/AddRentalOrderPage";
import { RentalOrderDetailsPage } from "./rentalOrders/RentalOrderDetailsPage/RentalOrderDetailsPage";
import { ReportsPage } from "./ReportsPage/ReportsPage";
import { StandardHeader } from "./structure/header/StandardHeader";
import { MenuPage } from "./structure/Page/MenuPage";
import { SupportUserWarning } from "./structure/SupportUserWarning/SupportUserWarning";
import { MyWorkTimesPage } from "./timeTracking/MyWorkTimesPage";
import { TollPage } from "./toll/TollPage/TollPage";
import { UnsupportedVersionModal } from "./UnsupportedVersionModal/UnsupportedVersionModal";
import { UpdateDetailsPage } from "./updatesInfo/UpdateDetailsPage/UpdateDetailsPage";
import { UpdateInfoPage } from "./updatesInfo/UpdateInfoPage/UpdateInfoPage";
import "@/styles/index.scss";
import "@/styles/reset.scss";
import "@/theme/variables.css";

dayjs.locale("de");
dayjs.extend(relativeTimePlugin);
dayjs.extend(customParseFormatPlugin);

const App = () => {
    useEffect(() => {
        Font.register({
            family: "Poppins",
            fonts: [
                { src: "https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJnedw.ttf" },
                { src: "https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6Z1JlEA.ttf", fontWeight: 500 },
                { src: "https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6Z1JlEA.ttf", fontWeight: 600 },
            ],
        });
    }, []);

    useNoSleep();
    useConfigureApiClients();

    const [authUser, setAuthUser] = useState<undefined | null | User>(undefined);
    useEffect(() => {
        return onAuthStateChanged(getAuth(), user => {
            setAuthUser(user);
            Firebase.instance().reflectAuthChange(user);
        });
    }, []);

    const [appUser] = useDocumentData(authUser?.uid ? Firebase.instance().getAppUser(authUser.uid) : undefined, [
        authUser?.uid,
    ]);

    useReportDiagnostics(appUser?.id);
    useWatchEmployeeDeactivation(authUser);

    const {
        organizationContext,
        appCompanyContext,
        loading: baseDataLoading,
        error: appCompanyDataError,
    } = useBaseData();

    const sideNavRef = useRef<HTMLDivElement>(null);
    const handleSideNavClick = () => {
        sideNavRef.current?.click();
    };

    return (
        <ContextWrapper
            authUser={authUser}
            appUser={appUser}
            organizationContext={organizationContext}
            appCompanyContext={appCompanyContext}>
            <IonApp>
                <IonReactRouter>
                    {Firebase.instance().getCompanyId() && <Notification />}
                    <StandardHeader onMenuToggleClicked={handleSideNavClick} />
                    <MenuPage
                        contentId="main"
                        ref={sideNavRef}
                        appCompanyDataError={appCompanyDataError}
                        baseDataLoading={baseDataLoading}>
                        <IonRouterOutlet id="main">
                            <Route component={NotFoundPage} />
                            <Route exact path={Routes.ORDERS_PROGRESS_SHARING} component={OrdersProgressSharingPage} />
                            <Route exact path={Routes.MAP_STRUCTURES_SHARING} component={MapStructuresSharingPage} />
                            <Route exact path={Routes.MAIL_ACTION} component={CustomMailActionPage} />
                            <Route exact path={Routes.HOME} component={HomePage} />
                            <Route exact path={Routes.BANKING_REDIRECT} component={BankConnectionRedirectPage} />
                            <Route exact path={Routes.ACCOUNT} component={AccountPage} />
                            <Route exact path={Routes.SETTINGS} component={GlobalSettingsPage} />
                            <Route exact path={Routes.REPORTS} component={ReportsPage} />
                            <Route exact path={Routes.ORGANIZATION_CUSTOMERS} component={CustomersPage} />
                            <Route exact path={Routes.ORGANIZATION_CUSTOMER_TAGS} component={CustomerTagsPage} />
                            <Route exact path={Routes.ORGANIZATION_CUSTOMER_MAP} component={CustomersMapPage} />
                            <Route
                                exact
                                path={Routes.ORGANIZATION_CUSTOMERS_SETTINGS}
                                component={CustomersSettingsPage}
                            />
                            <Route exact path={Routes.ORGANIZATION_CUSTOMERS_DETAILS} component={CustomerDetailsPage} />
                            <Route exact path={Routes.ANALYSIS_REVENUE} component={RevenueDevelopmentPage} />
                            <Route
                                exact
                                path={Routes.ORGANIZATION_LOADING_POINTS}
                                component={LoadingOrUnloadingPointsPage}
                            />
                            <Route exact path={Routes.ORGANIZATION_EMPLOYEES} component={EmployeesPage} />
                            <Route
                                exact
                                path={Routes.ORGANIZATION_EMPLOYEES_TIME_TRACKING}
                                component={EmployeesTimeTrackingsPage}
                            />
                            <Route exact path={Routes.ORGANIZATION_EMPLOYEES_DETAILS} component={EmployeeDetailsPage} />
                            <Route exact path={Routes.EMPLOYEES_MAP} component={LocationsMapPage} />
                            <Route exact path={Routes.ORGANIZATION_MACHINES} component={MachinesPage} />
                            <Route exact path={Routes.ORGANIZATION_MACHINE_DETAILS} component={MachineDetailsPage} />
                            <Route exact path={Routes.ORGANIZATION_MACHINE_HISTORY} component={MachineHistoryPage} />
                            <Route exact path={Routes.ORGANIZATION_SERVICES} component={ServicesPage} />
                            <Route exact path={Routes.ORGANIZATION_SERVICES_DETAILS} component={ServiceDetailsPage} />
                            <Route exact path={Routes.ORGANIZATION_SERVICES_CATALOG} component={ServiceCatalogPage} />
                            <Route exact path={Routes.ORGANIZATION_RESOURCES} component={ResourcesPage} />
                            <Route exact path={Routes.ORGANIZATION_RESOURCES_HISTORY} component={ResourceHistoryPage} />
                            <Route exact path={Routes.ORGANIZATION_RESOURCES_TAGS} component={ResourcesTagsPage} />
                            <Route exact path={Routes.ORGANIZATION_RESOURCES_DETAILS} component={ResourceDetailsPage} />
                            <Route exact path={Routes.ORGANIZATION_GASSTATIONS} component={GasStationsPage} />
                            <Route exact path={Routes.ORGANIZATION_SUPPLIERS} component={SuppliersPage} />
                            <Route exact path={Routes.ORGANIZATION_SUPPLIERS_DETAILS} component={SupplierDetailsPage} />
                            <Route
                                exact
                                path={Routes.ORGANIZATION_SUPPLIERS_SETTINGS}
                                component={SuppliersSettingsPage}
                            />
                            <Route exact path={Routes.ORGANIZATION_COMPANY} component={CompanyPage} />
                            <Route exact path={Routes.ORGANIZATION} component={OrganizationPage} />
                            <Route
                                exact
                                path={Routes.ORGANIZATION_PRICE_AGREEMENT_DETAILS}
                                component={PriceAgreementDetailsPage}
                            />
                            <Route exact path={Routes.ORGANIZATION_PRICE_AGREEMENTS} component={PriceAgreementsPage} />
                            <Route exact path={Routes.ORDER_DISPOSITION} component={DispositionPage} />
                            <Route exact path={Routes.ORDERS_OVERVIEW} component={OrdersAdministrationPage} />
                            <Route exact path={Routes.ORDERS}>
                                <Redirect to={Routes.ORDERS_OVERVIEW} />
                            </Route>
                            <Route exact path={Routes.ORDER_SETTINGS} component={OrderSettingsPage} />
                            <Route exact path={Routes.TIME_TRACKING} component={MyWorkTimesPage} />
                            <Route exact path={Routes.INTERNAL_WORK} component={InternalWorkPage} />
                            <Route exact path={Routes.TASKS} component={TasksPage} />
                            <Route exact path={Routes.TASK_DETAILS} component={TaskDetailsPage} />
                            <Route exact path={Routes.ORDER_DETAILS} component={OrderDetailsPage} />
                            <Route exact path={Routes.ORDER_CREATE} component={CreateOrderPage} />
                            <Route exact path={Routes.ORDERS_ARCHIVE} component={OrdersArchivePage} />
                            <Route exact path={Routes.ORDER_REQUESTS} component={OrderRequestsPage} />
                            <Route exact path={Routes.ORDER_REQUEST_DETAILS} component={OrderRequestDetailsPage} />
                            <Route exact path={Routes.ORDER_REQUEST_ARCHIVE} component={OrderRequestsArchivePage} />
                            {/*<Route exact path={Routes.NEWS} component={NewsPage} />*/}
                            <Route exact path={Routes.RECEIPTS} component={ReceiptMenuPage} />
                            <Route exact path={Routes.INCOMING_BILLS} component={IncomingBillPage} />
                            <Route exact path={Routes.INCOMING_BILLS_DETAILS} component={IncomingBillDetailsPage} />
                            <Route exact path={Routes.COST_ACCOUNTS} component={CostAccountsPage} />
                            <Route exact path={Routes.BILLS} component={BillsPage} />
                            <Route exact path={Routes.BILL_SETTINGS} component={BillSettingsPage} />
                            <Route exact path={Routes.BILL_CREATE} component={CreateBillPage} />
                            <Route exact path={Routes.BILL_CANCELLATION} component={CancellationBillPage} />
                            <Route exact path={Routes.BILL_DETAILS} component={BillDetailsPage} />
                            <Route exact path={Routes.BILL_ARCHIVE} component={BillsArchivePage} />
                            <Route exact path={Routes.BILL_REMINDERS_FOR_BILL} component={BillRemindersForBillPage} />
                            <Route exact path={Routes.BILL_REMINDERS} component={BillRemindersOverviewPage} />
                            <Route exact path={Routes.BILL_REMINDER_DETAILS} component={BillReminderDetailsPage} />
                            <Route exact path={Routes.DELIVERY_NOTES} component={DeliveryNotesPage} />
                            <Route exact path={Routes.DELIVERY_NOTES_ARCHIVE} component={DeliveryNotesArchivePage} />
                            <Route exact path={Routes.DELIVERY_NOTES_CREATE} component={CreateDeliveryNotePage} />
                            <Route exact path={Routes.DELIVERY_NOTES_DETAIL} component={DeliveryNoteDetailsPage} />
                            <Route exact path={Routes.DELIVERY_NOTES_SETTINGS} component={DeliveryNoteSettingsPage} />
                            <Route exact path={Routes.CREDIT_NOTES} component={CreditNotesPage} />
                            <Route exact path={Routes.CREDIT_NOTES_ARCHIVE} component={CreditNotesArchivePage} />
                            <Route exact path={Routes.CREDIT_NOTES_CREATE} component={CreateCreditNotePage} />
                            <Route exact path={Routes.CREDIT_NOTES_DETAIL} component={CreditNoteDetailsPage} />
                            <Route exact path={Routes.CREDIT_NOTES_SETTINGS} component={CreditNotesSettingsPage} />
                            <Route exact path={Routes.OFFERS} component={OffersPage} />
                            <Route exact path={Routes.OFFERS_CREATE} component={CreateOfferPage} />
                            <Route exact path={Routes.OFFERS_DETAIL} component={OfferDetailsPage} />
                            <Route exact path={Routes.OFFERS_SETTINGS} component={OfferSettingsPage} />
                            <Route exact path={Routes.OFFERS_ARCHIVE} component={OfferArchivePage} />
                            <Route exact path={Routes.SIGNIN} component={SignInPage} />
                            <Route exact path={Routes.SIGNUP} component={SignUpPage} />
                            <Route exact path={Routes.UPDATES_INFO} component={UpdateInfoPage} />
                            <Route exact path={Routes.UPDATES_INFO_DETAIL} component={UpdateDetailsPage} />
                            {/* Customer Portal Pages */}
                            <Route exact path={Routes.CONTRACTORS} component={ContractorsPage} />
                            <Route
                                exact
                                path={Routes.CONTRACTORS_ORDER_REQUESTS}
                                component={ContractorsOrderRequestPage}
                            />
                            <Route
                                exact
                                path={Routes.CONTRACTORS_ORDER_REQUEST_DETAILS}
                                component={ContractorsOrderRequestDetailsPage}
                            />
                            <Route
                                exact
                                path={Routes.CONTRACTORS_ORDER_REQUEST_CREATE}
                                component={ContractorsOrderRequestCreatePage}
                            />
                            <Route
                                exact
                                path={Routes.CONTRACTORS_ORDER_REQUEST_ARCHIVE}
                                component={ContractorsOrderRequestsArchivePage}
                            />
                            <Route exact path={Routes.CONTRACTOR_DETAILS} component={CollaborationPage} />
                            {/* End Customer Portal Pages */}
                            <Route exact path={Routes.RENTAL_ORDER_CREATE} component={AddRentalOrderPage} />
                            <Route exact path={Routes.RENTAL_ORDER_DETAILS} component={RentalOrderDetailsPage} />
                            <Route exact path={Routes.ORGANIZATION_RENTAL_SERVICES} component={RentalsOverviewPage} />
                            <Route exact path={Routes.CAMPAIGNS} component={CampaignsPage} />
                            <Route exact path={Routes.CAMPAIGN_DETAILS} component={CampaignDetailsPage} />
                            <Route exact path={Routes.PROJECTS} component={ProjectsPage} />
                            <Route exact path={Routes.PROJECT_DETAILS} component={ProjectDetailsPage} />
                            <Route exact path={Routes.TOLL} component={TollPage} />
                            <Route exact path={Routes.STRIPE_ERROR} component={StripeErrorPage} />
                            <Route exact path={Routes.LANDING} component={LandingPage} />
                        </IonRouterOutlet>
                    </MenuPage>
                    <PushNotifications />
                    <UnsupportedVersionModal />
                    <SupportUserWarning />
                </IonReactRouter>
            </IonApp>
        </ContextWrapper>
    );
};

export default App;
