import { immerable } from "immer";
import { Customer } from "./Customer";
import { Marker } from "./Marker";
import { MapStructuresSequence, Order } from "./Order";

export class OrderRequest {
    [immerable] = true;
    public id: string;
    public archived: boolean;
    public orderIds: Order["id"][];
    // Flag that indicates that one of the orders has been completed
    public hasACompletedOrder: boolean;
    public companyId: string;
    public status: OrderRequestStatus;
    /**
     * The time the order request was created as IsoString
     */
    public requestedAt: string | null;
    public customerId: Customer["id"];
    // needed because we do not want to load the customer object
    public customerName: string;
    /**
     * The first possible time the order should take place YYYY-MM-DD
     */
    public startDate: string;
    /**
     * The time last possible the order should take place YYYY-MM-DD
     */
    public endDate: string;
    public subject: string;
    public description: string;
    public fieldIds: string[];
    public trackIds: string[];
    public markers: Marker[];
    public loadingPointIds: string[];
    public mapStructuresSequence: MapStructuresSequence;
    public isMapStructuresSequenceEnabled: boolean;
    constructor(initialValues?: Partial<OrderRequest>) {
        this.id = initialValues?.id ?? "";
        this.archived = initialValues?.archived ?? false;
        this.companyId = initialValues?.companyId ?? "";
        this.status = initialValues?.status ?? OrderRequestStatus.PENDING;
        this.requestedAt = initialValues?.requestedAt ?? null;
        this.customerId = initialValues?.customerId ?? "";
        this.customerName = initialValues?.customerName ?? "";
        this.subject = initialValues?.subject ?? "";
        this.startDate = initialValues?.startDate ?? "";
        this.endDate = initialValues?.endDate ?? "";
        this.description = initialValues?.description ?? "";
        this.fieldIds = initialValues?.fieldIds ?? [];
        this.trackIds = initialValues?.trackIds ?? [];
        this.markers = initialValues?.markers ?? [];
        this.orderIds = initialValues?.orderIds ?? [];
        this.hasACompletedOrder = initialValues?.hasACompletedOrder ?? false;
        this.loadingPointIds = initialValues?.loadingPointIds ?? [];
        this.mapStructuresSequence = initialValues?.mapStructuresSequence ?? [];
        this.isMapStructuresSequenceEnabled = initialValues?.isMapStructuresSequenceEnabled ?? false;
    }
}

export enum OrderRequestStatus {
    DRAFT = "OrderRequestStatus_DRAFT",
    PENDING = "OrderRequestStatus_PENDING",
    ACCEPTED = "OrderRequestStatus_ACCEPTED",
    REJECTED = "OrderRequestStatus_REJECTED",
}

export function getNameForOrderRequestStatus(status: OrderRequestStatus) {
    switch (status) {
        case OrderRequestStatus.PENDING:
            return "Versendet";
        case OrderRequestStatus.ACCEPTED:
            return "Angenommen";
        case OrderRequestStatus.REJECTED:
            return "Abgelehnt";
        case OrderRequestStatus.DRAFT:
            return "Entwurf";
        default: {
            status satisfies never;
        }
    }
}
