import { Absence } from "@farmact/model/src/model/Absence";
import { MonthlyBillingData } from "@farmact/model/src/model/adminTools/MonthlyBillingData";
import { AlertNotification } from "@farmact/model/src/model/AlertNotification";
import { AppCompany } from "@farmact/model/src/model/AppCompany";
import { AppUser } from "@farmact/model/src/model/AppUser";
import { AreaMeasurement } from "@farmact/model/src/model/AreaMeasurement";
import { Bill } from "@farmact/model/src/model/Bill";
import { BillReminder } from "@farmact/model/src/model/BillReminder";
import { Campaign } from "@farmact/model/src/model/Campaign";
import { BillChangeHistoryEntry } from "@farmact/model/src/model/changeHistory/BillChangeHistoryEntry";
import { DeliveryNoteChangeHistoryEntry } from "@farmact/model/src/model/changeHistory/DeliveryNoteChangeHistoryEntry";
import { ResourceInventoryHistoryEntry } from "@farmact/model/src/model/changeHistory/ResourceInventoryHistoryEntry";
import { CompanyMeta } from "@farmact/model/src/model/CompanyMeta";
import { CostAccount } from "@farmact/model/src/model/CostAccount";
import { CreditNote } from "@farmact/model/src/model/CreditNote";
import { Customer } from "@farmact/model/src/model/Customer";
import { CustomerTag } from "@farmact/model/src/model/CustomerTag";
import { DatevConnection } from "@farmact/model/src/model/DatevConnection";
import { DeliveryNote } from "@farmact/model/src/model/DeliveryNote";
import { Employee } from "@farmact/model/src/model/Employee";
import { EmployeeLocation } from "@farmact/model/src/model/EmployeeLocation";
import { Field } from "@farmact/model/src/model/Field";
import { GasStation } from "@farmact/model/src/model/GasStation";
import { GeolocationTracking } from "@farmact/model/src/model/GeolocationTracking";
import { IncomingBill } from "@farmact/model/src/model/IncomingBill";
import { Invitation } from "@farmact/model/src/model/Invitation";
import { LiquidMixture } from "@farmact/model/src/model/LiquidMixture";
import { LoadingOrUnloadingPoint } from "@farmact/model/src/model/LoadingOrUnloadingPoint";
import { Machine } from "@farmact/model/src/model/Machine";
import { MachineCounterTracking } from "@farmact/model/src/model/MachineCounterTracking";
import { MachineMaintenanceRecord } from "@farmact/model/src/model/MachineMaintenanceRecord";
import { MailTrace } from "@farmact/model/src/model/MailTrace";
import { Marker } from "@farmact/model/src/model/Marker";
import { Offer } from "@farmact/model/src/model/Offer";
import { OperatingUnit } from "@farmact/model/src/model/OperatingUnit";
import { Order } from "@farmact/model/src/model/Order";
import { OrderRequest } from "@farmact/model/src/model/OrderRequest";
import { Overtime } from "@farmact/model/src/model/Overtime";
import { PriceAgreement } from "@farmact/model/src/model/PriceAgreement";
import { PriceAgreementSnapshot } from "@farmact/model/src/model/PriceAgreementSnapshot";
import { Project } from "@farmact/model/src/model/Project";
import { Refuel } from "@farmact/model/src/model/Refuel";
import { RentalOrder } from "@farmact/model/src/model/RentalOrder";
import { Resource } from "@farmact/model/src/model/Resource";
import { ResourceTag } from "@farmact/model/src/model/ResourceTag";
import { Service } from "@farmact/model/src/model/services/Service";
import { SharingToken } from "@farmact/model/src/model/SharingToken";
import { Supplier } from "@farmact/model/src/model/Supplier";
import { TemplateData } from "@farmact/model/src/model/TemplateData";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { TollRecord } from "@farmact/model/src/model/TollRecord";
import { Track } from "@farmact/model/src/model/Track";
import { UpdateInfo } from "@farmact/model/src/model/UpdateInfo";
import { UserMeta } from "@farmact/model/src/model/UserMeta";
import { CollectionReference, Firestore, collection, doc } from "firebase/firestore";
import { PartialConstructor, getModelConverter } from "./ModelConverter";

export class FirestoreCollections {
    constructor(public firestore: Firestore) {}

    public appCompanies() {
        return this.useConverter(collection(this.firestore, "companies"), AppCompany);
    }

    public operatingUnits(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "operatingUnits"), OperatingUnit);
    }

    public datevConnections(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "datevConnections"), DatevConnection);
    }

    public roles() {
        return collection(this.firestore, "roles");
    }

    public invitations() {
        return this.useConverter(collection(this.firestore, "invitations"), Invitation);
    }

    public appUsers() {
        return this.useConverter(collection(this.firestore, "users"), AppUser);
    }

    public employees(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "employees"), Employee);
    }

    public fields(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "fields"), Field);
    }

    public loadingOrUnloadingPoints(companyId?: AppCompany["id"]) {
        return this.useConverter(
            collection(doc(this.appCompanies(), companyId), "loadingPoints"),
            LoadingOrUnloadingPoint
        );
    }

    public markers(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "markers"), Marker);
    }

    public tracks(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "tracks"), Track);
    }

    public machines(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "machines"), Machine);
    }

    public suppliers(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "suppliers"), Supplier);
    }

    public machineCounterTrackings(companyId?: AppCompany["id"]) {
        return this.useConverter(
            collection(doc(this.appCompanies(), companyId), "machineCounterTrackings"),
            MachineCounterTracking
        );
    }

    public machineMaintenanceRecords(machineId: string, companyId?: AppCompany["id"]) {
        return this.useConverter(
            collection(doc(this.machines(companyId), machineId), "maintenanceRecords"),
            MachineMaintenanceRecord
        );
    }

    public gasStations(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "gasStations"), GasStation);
    }

    public refuels(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "refuels"), Refuel);
    }

    public customers(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "customers"), Customer);
    }

    public priceAgreements(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "priceAgreements"), PriceAgreement);
    }

    public priceAgreementSnapshots(companyId?: AppCompany["id"]) {
        return this.useConverter(
            collection(doc(this.appCompanies(), companyId), "priceAgreementSnapshots"),
            PriceAgreementSnapshot
        );
    }

    public customerTags(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "customerTags"), CustomerTag);
    }

    public resourceTags(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "resourceTags"), ResourceTag);
    }

    public resources(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "resources"), Resource);
    }

    public resourceInventoryChangeHistory(resourceId: string, companyId?: AppCompany["id"]) {
        return this.useConverter(
            collection(doc(this.resources(companyId), resourceId), "resourceInventoryChangeHistory"),
            ResourceInventoryHistoryEntry
        );
    }

    public orders(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "orders"), Order);
    }

    public orderRequests(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "orderRequests"), OrderRequest);
    }

    public geolocationTrackings(orderId: string, companyId?: AppCompany["id"]) {
        return this.useConverter(
            collection(doc(this.orders(companyId), orderId), "geolocationTrackings"),
            GeolocationTracking
        );
    }

    public liquidMixtures(orderId: string, companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.orders(companyId), orderId), "liquidMixtures"), LiquidMixture);
    }

    public decoupledOrderTimeTrackings(orderId: string, companyId?: AppCompany["id"]) {
        return this.useConverter(
            collection(doc(this.orders(companyId), orderId), "decoupledOrderTimeTrackings"),
            TimeTracking
        );
    }

    public employeeLocations(companyId?: AppCompany["id"]) {
        return this.useConverter(
            collection(doc(this.appCompanies(), companyId), "metaInfos", "lastLocations", "locations"),
            EmployeeLocation
        );
    }

    public services(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "services"), Service);
    }

    public rentalOrders(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "rentalOrders"), RentalOrder);
    }

    public rentalOrderAreaMeasurements(rentalOrderId: string, companyId?: AppCompany["id"]) {
        return this.useConverter(
            collection(this.rentalOrders(companyId), rentalOrderId, "measurements"),
            AreaMeasurement
        );
    }

    public timeTrackings(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "timeTrackings"), TimeTracking);
    }

    public overtimes(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "overtimes"), Overtime);
    }

    public notifications(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "notifications"), AlertNotification);
    }

    public bills(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "bills"), Bill);
    }

    public billChangeHistory(billId: string, companyId?: AppCompany["id"]) {
        return this.useConverter(
            collection(doc(this.bills(companyId), billId), "billChangeHistory"),
            BillChangeHistoryEntry
        );
    }

    public deliveryNoteChangeHistory(deliveryNoteId: string, companyId?: AppCompany["id"]) {
        return this.useConverter(
            collection(doc(this.deliveryNotes(companyId), deliveryNoteId), "deliveryNoteChangeHistory"),
            DeliveryNoteChangeHistoryEntry
        );
    }

    public billReminders(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "billReminders"), BillReminder);
    }

    public deliveryNotes(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "deliveryNotes"), DeliveryNote);
    }

    public creditNotes(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "creditNotes"), CreditNote);
    }

    public offers(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "offers"), Offer);
    }

    public incomingBills(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "incomingBills"), IncomingBill);
    }

    public costAccounts(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "costAccounts"), CostAccount);
    }

    public sharingTokens(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "sharingTokens"), SharingToken);
    }

    public companyMails(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "mails"), MailTrace);
    }

    public absences(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "absences"), Absence);
    }

    public monthlyBillingData(companyId?: AppCompany["id"]) {
        return this.useConverter(
            collection(this.firestore, "billingData", companyId ?? "", "months"),
            MonthlyBillingData
        );
    }

    public companiesMeta() {
        return this.useConverter(collection(this.firestore, "companiesMeta"), CompanyMeta);
    }

    public usersMeta() {
        return this.useConverter(collection(this.firestore, "usersMeta"), UserMeta);
    }

    public templateData() {
        return this.useConverter(collection(this.firestore, "templateData"), TemplateData);
    }

    public serviceTemplates() {
        return this.useConverter(collection(this.firestore, "templateData/serviceTemplatesData/services"), Service);
    }

    public updateInfos() {
        return this.useConverter(collection(this.firestore, "updateInfos"), UpdateInfo);
    }

    public campaigns(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "campaigns"), Campaign);
    }

    public projects(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "projects"), Project);
    }

    public tollRecords(companyId?: AppCompany["id"]) {
        return this.useConverter(collection(doc(this.appCompanies(), companyId), "tollRecords"), TollRecord);
    }

    private useConverter<T>(repo: CollectionReference, model: PartialConstructor<T>) {
        return repo.withConverter(getModelConverter(model));
    }
}
